
   .mask {
    position: relative;
    height: 100vh; 
    width: 100%;
}

.mask-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; 
}

.mask-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #000; 
}

.mask-container p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #000; 
}

.get-started-button {
    color: #0056b3; 
    border: 1px solid#0056b3;
    background-color: transparent;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.get-started-button a{
    color: #0056b3;
}
.get-started-button:hover {
    background-color: #0056b3;
    color: #fff;
}
.get-started-button:hover a{
    color: #fff;
}

.get-started-button span {
    margin-left: 10px;
}

.get-started-button span svg {
    height: 20px;
    vertical-align: middle;
}
.bgimage{
  position: relative;
  height: 100vh;

}
