.statics {
    padding: 10px 30px;
    background-color: #f8f8f8;
   }
   
.container {
    text-align: center;
    margin: 0 15px;
   }

.row{
    display: flex;
    justify-content: space-evenly;
}   
 .inner{
    text-align: center;
    background-color: transparent;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 15px;
    transition: transform 1s ease;  
 }  
 .inner:hover span{
    transform: translateY(-5px);
 }
 .inner span{
    width: 80px;
    height: 80px !important;
    background-color: #e8282f;
    display: inline-block;
    border-radius: 50%;
    padding: 15px;
 }
 .inner img{
    width: 50px;
    height: 50px;
    filter: invert(1);
 }
 .inner h3{
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0px 0px;
 }
 .inner h4{
    font-size: 1rem;
    font-weight: 400;
    width: 250px;
 }
   
