  .recentwork {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 14rem;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .upper-div {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header-work {
    font-size: 30px;
    color: #e8282f;
    font-weight: 600;
  }
  
  .subhead-work {
    font-size: 1.2rem;
    color: #666;
  }
  
  .lower-div {
    text-align: center;
  }
  
  .lower-header {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.2rem;
    padding-left: 1rem;
  }
  
  .lower-subhead {
    font-size: 1rem;
    color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cta {
    background-color: #e8282f;
    color: #fff;
    border: none;
    padding: 0.2rem 1rem;
    margin-bottom: 0.5rem;
    margin-left: 0.4rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta:hover {
    background-color: #0056b3;
  }
  

  