.header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    padding-top: 1.5rem;
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 10;
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
   transition: 1s ease-in-out;
    */
    .nav-menu a{
        color:#151513;
    }
}
.header-bg{
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    align-items: center;
    height: 80px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000 !important;
    /* transition: 1s ease; */
    .nav-menu a{
        color: #151513;
    }
}
.header,
.header-bg {
  transition: 0.2s ;
}
.nav-menu{
    display: flex;

}
.nav-menu li{
    padding: 0 1.5rem;
}
.nav-menu li a{
    font-size:1.2rem;
    font-weight: 400;
    color:#151513;
    cursor: pointer;
}
.nav-menu li a.active {
    color: #e8282f; 
    font-weight: bold; 
    /* transform: translateZ(-19px); */
  }
.nav-menu li a:hover{
    text-decoration: 2px #e8282f underline;
    
}

.logo{
    width:20rem;
    height: 15rem;
    margin-top: 6rem;
    transition: 0.4s;
}
#contact-us {
    padding: 1px 6px;
    color: #fff;
    background-color: #e8282f; 
    border-radius: 5px;
    transition: background-color 0.3s ease;     
  }
  #contact-us a{
    color: #fff;
  }
  
  #contact-us:hover {
    background-color: #db4f48; /* change color on hover */
  }
.hamburger{
    display: none;
}
@media screen and (max-width: 1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(248, 88, 88, 0.251);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }
    .nav-menu.active{
        left:0;
        padding: 1rem;
        line-height: 1.2rem;
    }
    .nav-menu li{
        padding: 0.5rem 0;
    }
    .nav-menu li a{
        padding: 2rem;
        font-weight: 600;
        color:#db4f48;
    }
    .hamburger{
        display: initial;
    }
    .header-bg{
        background-color: #fff;
    }

}
