.services-container {
    padding: 2rem 1rem;
    padding-bottom: 0;
    height: auto;
    width: 100%;
    background-color: #ffffff35;
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    overflow: hidden;
  }
  
  .cards-banner {
    position: relative;
    width: 1400px;
    padding: 1rem 10.5rem;
    margin-left: 3rem;
    overflow: none;
    background-color: transparent;
  }
  
  .element {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .element:hover{
    transform: translateY(-2px);
  }
  
  .element img {
    width: 14rem;
    height: 20rem;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .element h3 {
    font-size: 1.2rem;
    font-weight: 100;
    margin: 0 0 10px;
    color: #e8282f;
  }
  
  .element-down{
    padding-top: 2rem;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .element-down:hover{
    transform: translateY(-2px);
  }
  .element-down img {
    width: 14rem;
    height: 20rem;
    border-radius: 8px;
    border: 1px solid #efefef;
    object-fit: cover;
  }
  .element-down h3 {
    font-size: 1.5rem;
    font-weight: 100;
    margin: 0 0 10px;
    color: #e8282f;
  }
  .maincontainer{
    position: relative;
    width: 14rem;
    height: 20rem;
    perspective: 1000px;
    
  }
  .thecard{
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style:preserve-3d ;
    transition: all 1s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 1000; */
    border-radius: 8px;
    background-color: transparent;
  }
  .thecard:hover{
    transform: rotateY(180deg);
    
  }
  .thefront{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

  }
  .theback{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: #1C46F2;
    border-radius: 12px;
    color: #151513;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 1rem;
    transform: rotateY(180deg);
    color: #fff;
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;

  }
  
  .theback li {
    list-style: none;
    width: 200px;
  }
  .theback li::before {
    content: "\2192"; 
    margin-right: 1rem;
  }
  
  .row-service{
    width: 100%;
    height: 60px;
    padding-top: 1rem;
    margin-bottom: 3rem;
 }
 .hp-title-service{
    font-size: 30px;
    font-weight: 100;
    color: #e8282f;
    text-align: center;
 }
  
.react-multiple-carousel__arrow{
  background: red !important;
  color: #fff;
}