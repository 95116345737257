 .banner{
    height: auto;
    width: 100%;
 }
 .banner-container{
    width: 100%;
    padding: 3rem 14rem;
    background-color: #fff;
 }
 .position-relative {
    position: relative!important;
}
 .row{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
 }
 .hp-title{
    font-size: 1.875rem;
    line-height: 1rem;
    font-weight: 600;
    color: #e8282f;
    margin-bottom: 1.313rem;
    text-align: center;
 }
 .subhead{
    font-size: 1.125rem;
    line-height: 1.563rem;
    font-weight: 300;
    color: #000;
    text-align: center;
    padding: 0 2rem;
 }
 .title-card{
    position: relative;
    display: block;
    background-color: transparent;
    overflow: hidden;
    height: 18rem;
    width: 18rem; 
 }
 .title-card-bg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
 
.title-card-icon{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 38px;
    height: 38px;
    background: blue;
    border-radius: 100%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
}
.title-card-title{
  position: absolute;
  bottom: 10px;
  left: 30px;
  font-size: 24px;
  font-weight: bold;
  background-color: transparent;
}
.title-card-title span{
    font-size: 20px;
    line-height: 36px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    color: #fff;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-top:1rem;
    padding-left: 3rem;
  }

.title-card-body{
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    background-color: #ff00098c;
    backdrop-filter: blur(4px);
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
    text-align: center;
}
.title-card:hover .title-card-body{
    left:0;
    top:0;
}
.title-card:hover .title-card-title span{
    display: none;
}
.title-card:hover .title-card-icon{
    display: none;
}
.card-title{
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
}
.card-sub-title{
    text-transform: capitalize;
    font-size: 13px;
    color: #fff;
}
.card-info{
    font-size: 13px;
    line-height: 25px;
    margin: 40px 0;
    font-weight: 400;
    color: #fff;
}   
.card-btn{
    color:#fff;
    background-color: blue;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    border: none;
    outline: none;
    font-weight: 500;
    cursor: pointer;
    width: 150px;
    margin-left: 2.4rem;
    text-align: center;
}
