@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,600&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;

}

body{
  background: #fff;

}
h1,h2,h3,h4,h5,p,a{
  color: #000;
  text-decoration: none;
   font-family: 'Poppins', sans-serif;;
}

ul{
  list-style: none;
}