.review {
    padding: 5rem 10rem;
    background-color: #fff;
    width: 100%;
    height: 100vh;
  }
  
  .review-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .heading {
    font-size: 30px;
    font-weight: 500;
    color: #e8282f;
    text-transform: uppercase;
  }
  
  .review-sub-heading {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .sub-one {
    font-size: 20px;
    color: #666;
  }
  
  .review-box {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    padding-left: 2.5rem;
  }
  
  .review-card {
    width: 25rem;
    height: 25rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    padding: 20px 26px;
    text-align: center;
    border-radius: 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .review-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
  .review-card:hover .body-hover{
    top: 45%;

  }
  
  .profile {
    width: 5rem;
    height: 5rem;
    background-color: #ddd;
    border-radius: 50%;
    margin: 20px auto;
  }
  
  .name {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    color: #1C46F2;
    font-family: Nunito, Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  
  .designation {
    text-align: center;
    font-size: 15px;
    color: #666;
    margin-top: 5px;
  }
  
  .star-image {
    width: 100%;
    height: 60px;
    margin-top: 3px;
  }
  .star-image img{
    height: 100px;
    width: 150px;
  }
  .review-text {
    padding: 20px;
    font-size: 14px;
    text-align: justify;
    color: #151513;
  }
  .body-hover{
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    position: absolute;
    border-radius: 50%;
    background-color: #ff00082f;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
