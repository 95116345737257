/* .Testimonials {
    height: auto;
    width: 100%;
    background-color: #fff;
    padding: 5rem 17rem;
    padding-top: 5rem;
  }
  
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    font-size: 18px;
    background: #fff;
  }
  .review-section{
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem;
    border-radius: 1rem;
    border: 0.025px solid#ccccccd7;
    box-shadow:0px 0px 1px #ccc;
  }
    .review-message{
    background-color: #fff;
    border-radius: 1rem;
  }
  .review-message p{
    text-align: center;
    color: #232320;
    font-size: 14.5px;  
    line-height: 1rem;
    font-style: italic;
    font-weight: 400;
  }
  .review-section img{
    max-width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 0.1rem solid#e8282f;
  }
  .review-message::before{
    content: "";
    position: absolute;
    bottom: -10rem;
    left: 50%;
    transform: translate(300%,-65%);
    border: 3rem solid#fff;
    border-color: transparent;
    border-top-color:#ced3ff98;
  }
.review-client-data{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:4rem;
}
.client-data-details{
  width: 300px;
  margin-left: 1rem;
  line-height: 1.5rem;
  text-align: center;
  
}
.swiper-button-prev{
  color: #e8282f;
}
.swiper-button-next{
  color: #e8282f;
} */
.Testimonials {
  width: 100%;
  padding: 3rem 12rem;
  padding-bottom: 4rem;
  background-color: #fff;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  font-size: 18px;
  background: #fff;
}
.hp-title {
  text-align: center;
}
.review-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5rem 5rem;
  background-color: #f9f9f9;
  border: 0.025px solid#ccccccd7;
  border-radius: 10px;
}
.review-client-data {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.client-data-details {
  margin-left: 20px;
}

.client-data-details p {
  margin: 5px 0;
}

.review-message {
  position: relative;
  padding: 2rem 0rem;
}
.review-section img{
  max-width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 0.1rem solid#e8282f;
}

.review-message p {
  font-size: 1rem;
  text-align: center;
  color: #151513;
  line-height: 1rem;
  font-style: italic;
}
.review-message .fa-quote-left,
.review-message .fa-quote-right {
  position: absolute;
  font-size: 2.5rem;
  color: #0056b3;
}

.review-message .fa-quote-left {
  top: -20px;
  left: -40px;
}

.review-message .fa-quote-right {
  bottom: -20px;
  right: -40px;
}