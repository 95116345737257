.card {
  height: auto;
  width: 100%;
  background-color: transparent;
}
.card-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.card-image{
  height: 30rem;
  width: 50%;
  background-size: cover;
  padding: 1rem;
 
}
.card-image img{
  width: 30rem;
  height: 28rem;
  /* padding-left: 2rem; */
}
.card-header {
  flex: 1;
  padding: 30px;
  border-radius: 5px;
}

.card-heading {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.card-sub {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.bar {
  display: block;
  height: 2px;
  width: 50px;
  margin-bottom: 10px;
  background-color: #db4f48;
  transition: width 0.3s ease-in-out;
}

.card-container:hover .bar {
  width: 100%;
}
.aboutus {
  display: flex;
  align-items: center; 
  justify-content: center;
  background:transparent;
}

.shadow-box {
  flex: 1;
  padding: 20px; 
}

.aboutcontent {
  flex: 1; 
  background-color: transparent;
}

.aboutcontent p {
  margin: 0; 
  margin-left: 2rem;
  text-align: center;
  width: 100%;

}


.shadow-box img {
  max-width: 100%;
  height: auto; 
}
.card-container-vision{
display: flex;
align-items: center;
padding:0 160px;
background-color: #fff;
}



@media screen and (max-width: 1024px){
  .card-container-vision{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    }
    .card-header {
      display: flex;
      flex-direction: column;
      padding: 15px;
    }
    
}