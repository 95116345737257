.Contactus{
    width: 100%;
    /* background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%); */
    /* background: linear-gradient(107.2deg, rgb(150, 15, 15) 10.6%, rgb(247, 0, 0) 91.1%); */
    background: linear-gradient(111.4deg, rgb(246, 4, 26) 0.4%, rgb(251, 139, 34) 100.2%);
    padding: 5rem 12rem;
    padding-top: 3rem;
    padding-left: 13rem;
}

.form-box {
    max-width: 50rem;
    margin: 0 auto;
    padding-top: 2rem;
  }
  
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 0.937rem;
  }
  
  input[type="text"],
  input[type="number"],
  input[type="email"] {
    flex: 1;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #fff;
    color: #151513;
  }
  
  
  button[type="submit"] {
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 5px;
    background-color:#0056b3 ;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  
  button[type="submit"]:hover {
    background-color: #0033b3;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    height: 50%;
    border-radius: 1rem;
    background-color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .modal.show {
    opacity: 1;
  }
  .modal-content {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 1rem;
   
    text-align: center;
  }
  .modal-content img{
    width: 200px;
    height: 200px;
  }
  .modal-content h5{
    font-size: 30px;
  }
  
  .close-modal {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #0033b3;
  }
  
  .close-modal:hover {
    color: #ccc;
  }