.footer {
  width: 100%;
  background-color: #212121;
  color: #fff;
  padding-top: 2rem;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}

.Logo-section,
.useful-links,
.our-services,
.contact,
.location {
  flex-basis: 25%;
  padding: 0 20px;
}
.Logo-section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo-heading h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #FFF;
}

.logo-sub-head p {
  font-size: 0.9rem;
  margin-bottom: 20px;
  color: #fff;
}

.logo-btn {
  background-color: transparent;
  color: #fff;
  border: 1px solid #e8282f;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.9rem;
}
.logo-btn:hover{
  background-color: #e8282f;
}

.use-head,
.service-head {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.location h1{
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #fff;
}
.use-list,
.service-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.use-list li,
.service-list li {
  font-size: 0.9rem;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.use-list li:hover,
.service-list li:hover {
  color: #e8282f;
}

.addresss,
.phone-number {
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.addresss span{
  line-height: 1;
}
.icons {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.icon-box {
  display: flex;
  align-items: center;
  margin-right: 0.5rem ;
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
}
.icon-box:hover{
  border: 2px solid #fafafa;
}

.copyright {
  background-color: #212121;
  color: #fff;
  padding: 12px 3px;
  text-align: left;
  width: 100%;
  margin-top: 8px;
  padding-left: 150px;
}

